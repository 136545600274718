import React from "react";
import { Link } from "gatsby";
import Navigation from "./Navigation";

const Heading = ({ herosection, locale, navbar, slug }) => {
  return (
    <div className="service_upper_class">
      <div className="serviceHeroSection" id="heading">
        <div className="containerwrap">
          <h4 className="serviceHeroSectionTitle ">{herosection.title}</h4>
          <div className="deveps-service">
            <h1 className="serviceHeroSectionHeading roboto">
              {herosection.heading1}
              <span style={{ color: "#58C9E8" }}>{herosection.heading2}</span>
            </h1>
          </div>

          <div
            className="serviceHeroSectionDescription open-sans"
            dangerouslySetInnerHTML={{ __html: herosection.des1 }}
          />
          <div className="serviceHeroSectionDescription open-sans">
            {herosection.des2}{" "}
          </div>
          <div className="opsBtnwrap middelBtn">
            <Link className="opsBtnlink" to={`/contact-us/`}>
              <p>{herosection.btn}</p>
            </Link>
          </div>
        </div>
      </div>
      <div className="serviceNavSection">
        <Navigation data={navbar} locale={locale} slug={slug} />
      </div>
    </div>
  );
};

export default Heading;
