import React, { useState, useEffect } from "react";

import plus from "../../../../src/images/plus.svg";
import minus from "../../../../src/images/minus.svg";

const Faq = ({ faq }) => {
  const [isMobileView, setIsMobileView] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const checkMobileView = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  const togglePlusButton = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const questionTexts = [
    "What Are The Benefits Of Outsourcing Devops Services?",
    "How Does DevOps Improve Software Development Processes?",
    "What Are Some Key DevOps Tools and Technologies?",
    "What Are The Challenges in Implementing DevOps?",
    "What Are The Best Practices for DevOps Implementation?",
  ];

  const answerData = [
    "Opsios Devops Services And Solutions Encompass Devops Implementation Services, Ci/cd Services, And Release And Configuration Management. They Specialize Infrastructure As Code (Lac) Services, Containerization, And Devsecops, Ensuring Seamless Integration And Robust Security In Software Development Processes-   Opsios Devops Services And Solutions Encompass Devops Implementation Services, Ci/cd Services, And Release And Configuration Management. They Specialize Infrastructure As Code (Lac) Services, Containerization, And Devsecops, Ensuring Seamless Integration And Robust Security In Software Development Processes-",
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    "Answer for question 3",
    "Answer for question 4",
    "Answer for question 5",
  ];

  return (
    <div className="background-color row">
      <div className="global-margin">
        <div className="margin-top-botton">
        <div className="global-services-title roboto global-title-color mb-5">
          FAQ: DevOps
        </div>
        <div className="d-flex justify-content-between ">
          <div className={`${isMobileView ? "col-12 text-center" : "col-md-5 p-1"}`}>
            {questionTexts.map((text, index) => (
              <div key={index} className="d-flex faq-dev my-2">
                <div className="text-light devops-faqs-ques  mt-4 text-start ms-3">
                  {text}
                </div>
                <img
                  className="pointer mt-3 text-end"
                  onClick={() => togglePlusButton(index)}
                  src={activeIndex === index ? minus : plus}
                  alt={activeIndex === index ? "Minus" : "Plus"}
                />
              </div>
            ))}
          </div>
          <div className={` ${isMobileView ? "col-12 backround-card p-4" : "col-6 p-4 pt-0"}`}>
          <div className="taperzoid mx-5 py-3"></div>
          <div className={`backround-card p-5 ${isMobileView ? "backround-card" : ""}`}>
            <div className="global-title-color devops-faqs-ques text-start pb-5 ">
              WHAT ARE THE BENIFITS OF OUTSATANDING DEVOPS SERVICE?
            </div>
            {activeIndex !== null && (
              <div className="text-light global-services-desc ">
                {answerData[activeIndex]}
              </div>
            )}
          </div>
          </div>
        </div>
        </div>

      </div>
    </div>
  );
};

export default Faq;
