import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import VisibilitySensor from "react-visibility-sensor";
import Layout from "../components/layoutnew";
import Heading from "../components/solutions/service/Heading";
import Concept from "../components/solutions/service/concept";
import Approach from "../components/solutions/service/approach";
import Roadmap from "../components/solutions/service/roadmap";
import Features from "../components/solutions/service/features";
import Faq from "../components/solutions/service/faq";
import "../styles/service.scss";
import SEO from "../components/seo";
import { graphql } from "gatsby";
const SolutionServices = ({ data }) => {
  const getServiceData = (data) => {
    const values = data.allStrapiSolutionService.edges[0].node;
    return values;
  };
  const getData = getServiceData(data);
  const getSolutionData = data.allStrapiService.edges[0].node;

  return (
    <Layout slug={getData.slug} locale={getData.locale}>
      <SEO
        slug={getData.slug}
        locale={getData.locale}
        title={getData.metaTitle}
        description={getData.metaDes}
      />
      <Heading
        herosection={getData.heroSection}
        locale={getData.locale}
        slug={getData.slug}
        navbar={getData.menuBar}
      />

      <VisibilityWrap slug={getData.slug} sectionId="overview">
        <Concept
          overview={getData.overview}
          concept={getSolutionData.solutionContent}
        />
      </VisibilityWrap>

      <VisibilityWrap slug={getData.slug} sectionId="approach">
        <Approach approach={getData.approach} />
      </VisibilityWrap>

      <VisibilityWrap slug={getData.slug} sectionId="roadmap">
        <Roadmap />
      </VisibilityWrap>
      <VisibilityWrap slug={getData.slug} sectionId="features">
        <Features />
      </VisibilityWrap>
      <VisibilityWrap slug={getData.slug} sectionId="faq">
        <Faq />
      </VisibilityWrap>
    </Layout>
  );
};

export default SolutionServices;

const VisibilityWrap = ({ slug, sectionId, children }) => {
  const [active, setActive] = useState(false);
  const [screenHeight, setScreenHeight] = useState(0);

  setTimeout(() => {
    setActive(true);
  }, 1200);

  useEffect(() => {
    const handler = () => {
      setActive(false);
      setTimeout(() => {
        setActive(true);
      }, 1000);
    };

    setScreenHeight(window.innerHeight);

    const upperClass = document.getElementsByClassName(
      "service_upper_class"
    )[0];

    upperClass.addEventListener("click", handler);

    return () => {
      upperClass.removeEventListener("click", handler);
    };
  });

  return (
    <VisibilitySensor
      active={active}
      partialVisibility={true}
      delayedCall={true}
      offset={{
        top: screenHeight > 0 ? screenHeight / 2 : 300,
        bottom: screenHeight > 0 ? screenHeight / 2 : 300,
      }}
      onChange={(isVisible) => {
        if (isVisible) {
          navigate(`/${slug}/#${sectionId}`);
        }
      }}
    >
      {children}
    </VisibilitySensor>
  );
};

export const query = graphql`
  query SolutionServices($slug: String!, $locale: String!) {
    allStrapiService(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          solutionContent {
            concept {
              title
              description
            }
          }
        }
      }
    }
    allStrapiSolutionService(
      filter: { locale: { eq: $locale }, slug: { eq: $slug } }
    ) {
      edges {
        node {
          locale
          slug
          metaTitle
          metaDes
          approach {
            apprch1
            apprch2
            apprch3
            apprch4
            des
            heading
            title
          }
          benifits {
            title
            heading
            services
          }
          cloudjourney {
            btn
            email
            title
          }
          heroSection {
            btn
            des1
            des2
            heading1
            heading2
            title
          }
          menuBar {
            l1
            l2
            l3
            l4
            l5
            l6
            l7
          }
          platform {
            title
          }
          process {
            des1
            des2
            heading
            services
            title
          }
          overview {
            title
            heading
            heading2
            heading3
            heading5
            heading6
            heading7
            heading9
            des1
            des2
            des3
            des4
            des5
            des6
            des7
            des8
            des9
            des10
            des11
            list3 {
              item
            }
            list4 {
              item
            }
            list5 {
              item
            }
          }
          whatwedo {
            bulletpoints {
              des
              title
            }
            headingGlance
            descGlance
            bulletPointsForGlance
            titleMigrate
            headingMigrate
            descMigrate
            bulletPointsForMigrate {
              title
              des
            }
            heading
            heading1
            title
          }
        }
      }
    }
  }
`;
