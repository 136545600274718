import React, { useState, useEffect } from "react";

import one from "../../../../src/images/01.svg";
import two from "../../../../src/images/blue-two.png";
import three from "../../../../src/images/blue-three.png";
import four from "../../../../src/images/blue-four.png";
import five from "../../../../src/images/blue-five.png";
import six from "../../../../src/images/blue-six.png";
import dot from "../../../images/dot.png";

const Roadmap = ({ roadmap }) => {
  const [isMobileView, setIsMobileView] = useState(false);

  const checkMobileView = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  return (
    <div className="row background-color">
      <div className="global-margin">
        <div
          className={`${
            isMobileView
              ? "col-12 text-center"
              : "global-services-title global-title-color  roboto"
          }`}
        >
          DevOps Evolution: Your Opsio Roadmap To Success
        </div>
        <div
          className={`d-flex ${
            isMobileView ? "col-12 mt-2" : " justify-content-between"
          } p-5`}
        >
          <img className="mx-5 px-2" src={one} width={75} alt="one" />
          <img className="mx-5  px-2" src={two} width={75} alt="two" />
          <img className="mx-5  px-2" src={three} width={75} alt="three" />
          <img className="mx-5  px-2" src={four} width={75} alt="four" />
          <img className="mx-5  px-2" src={five} width={75} alt="five" />
          <img className="mx-5  px-2" src={six} width={75} alt="six" />
        </div>
        <div className={`d-flex ${isMobileView ? " col-12 mt-2" : ""} p-5`}>
          <p className="text-light text-center px-5 progress h-100 mt-1">
            Free
          </p>
          <hr class="new1"></hr>
          <p className="text-light text-center px-5 progress h-100 mt-1">
            Free
          </p>
          <hr class="new1"></hr>
          <p className="text-light text-center px-5 progress h-100 mt-1">
            Free
          </p>
          <hr class="new1"></hr>
          <p className="text-light text-center px-5 progress h-100 mt-1">
            Free
          </p>
          <hr class="new1"></hr>
          <p className="text-light text-center px-l-r progress1 h-100  mb-1">
            Subscription
          </p>
        </div>
        <div className={`d-flex ${isMobileView ? "col-12 mt-2" : ""} `}>
          <div className="row">
            <caption className="text-light service-header mx-5 ">
              Introduction
            </caption>
            <span className="text-light px-5 progress-text roadmap">
            <img className="me-1" src={dot} width={5} alt="dot" />
              <span className="text-light  mt-5 font-weight-700 font-size-20 line-height-30 ">
                Walk through and explanation of the equipment & related assets
              </span>
            </span>
          </div>
          <div className="row ps-1">
            <caption className="text-light service-header ">
              Technical Workshop
            </caption>
            <span className="text-light roadmap mb-5">
              <span className="text-light progress-text ">
              <img className="me-1" src={dot} width={5} alt="dot" />
                POC 1 Plan
              </span>
              
              <img className="me-1" src={dot} width={5} alt="dot"/>
              <span className="text-light progress-text ">
                POC 1 Plan
              </span>
          
              <img className="me-1" src={dot} width={5} alt="dot" />
              <span className="text-light progress-text  capitalize">
                POC 1 Plan
              </span>
            </span>
          </div>
          <div className="row ps-5">
            <caption className="text-light service-header  capitalize">
              Presentation Solution
            </caption>
            <span className="text-light roadmap mb-2">
            <img className="me-1" src={dot} width={5} alt="dot" />
              <span className="text-light progress-text mt-3  capitalize">
                Weekly or biweekly meetings to provide updates and maintain
                communication cadence
              </span>
            </span>
          </div>
          <div className="row ps-1">
            <caption className="text-light service-header mx-2 ">
              Presentation of PoC
            </caption>
            <p className="text-light progress-text ms-2">
              <span className="text-light roadmap roadmap-text">
              <img className="me-1" src={dot} width={5} alt="dot"/>
                <span className="text-light progress-text   capitalize">
                  Presentation of the findings of the POCS
                </span>
                <br/>
                <br/>
                <img className="me-1" src={dot} width={5} alt="dot" />
                <span className="text-light progress-text   capitalize">
                  Demonstration of the POCs, showcasing the key functionalities'
                </span>
              </span>
            </p>
          </div>
          <div className="row ps-5">
            <caption className="text-light service-header ">
              Integration To Production
            </caption>
            <span className="roadmap mb-5">
            <img className="me-1" src={dot} width={5} alt="dot" />
              <span className="text-light progress-text   mb-5 mt-1 capitalize">
                Integration with factory data and systems - connecting the
                solutions with the existing system
              </span>
            </span>
          </div>
          <div className="row ps-4">
            <caption className="text-light service-header ">
              Deploy, Run & Optimize
            </caption>
            <span className="roadmap  mt-3">
            <img className="me-1" src={dot} width={5} alt="dot"/>
              <span className="text-light progress-text   capitalize">
                Deployment of the integrated solution and Optimization based on
                production feedbacks to enhance efficiency
              </span>
            </span>
          </div>
        </div>
        <div className="text-center margin-top-botton py-5">
          <button type="submit" className="src_btn">
            Get Start Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
