import React, { useState, useEffect } from "react";

const Approach = ({ approach }) => {
  const [isMobileView, setIsMobileView] = useState(false);

  const checkMobileView = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  return (
    <div className="row background-color">
      <div className="global-margin">
        <div className={` ${isMobileView ? " " : "margin-top-botton"}`}>
          <div className="global-services-heading  text-uppercase my-2">
            Flexible cooperation
          </div>
          <span className="global-services-desc text-light   capitalize">
            Tailor your approach for optimal efficiency and results with Opsio's
            versatile advantages.{" "}
          </span>
          <div className={`row py-5 ${isMobileView ? "flex-column" : ""}`}>
            <div
              className={`col-md-4 ${
                isMobileView ? "col-12 mt-2" : ""
              } global-services-box-bg`}
            >
              <div className="text-light title roboto">Managed DevOps</div>
              <p className="text-light global-services-desc  capitalize">
                A comprehensive service that moves the entire IT infrastructure
                management process to our environment. We take full
                responsibility for the continuity of work and team management.
              </p>
            </div>
            <div
              className={`col-md-4 ${
                isMobileView ? "col-12 mt-2" : ""
              } global-services-box-bg`}
            >
              <div className="text-light title roboto">Project DevOps</div>
              <p className="text-light global-services-desc  capitalize">
                We assist in defining the scope of the project, its goals and
                resource requirements. We take full responsibility for the
                delivery of outcomes that meet the defined criteria.
              </p>
            </div>
            <div
              className={`col-md-4 ${
                isMobileView ? "col-12 mt-2" : ""
              } global-services-box-bg`}
            >
              <div className="text-light title roboto">Team augmentation</div>
              <p className="text-light global-services-desc  capitalize">
                Our engineers can join your organization to support your
                internal IT teams in their day-to-day work. We will complete
                tasks and share our knowledge and experience on an ongoing
                basis.
              </p>
            </div>
          </div>
          <div className={`row py-5 ${isMobileView ? "flex-column" : ""}`}>
            <div
              className={`col-md-4 ${
                isMobileView ? "col-12 mt-2" : ""
              } global-services-box-bg`}
            >
              <div className="text-light title roboto">
                Focused Business Operations
              </div>
              <p className="text-light global-services-desc  capitalize">
                This is the recommended path For companies that want to stay
                focused on the core of their business operations.
              </p>
            </div>
            <div
              className={`col-md-4 ${
                isMobileView ? "col-12 mt-2" : ""
              } global-services-box-bg`}
            >
              <div className="text-light title roboto">
                Optimizing IT for Success{" "}
              </div>
              <p className="text-light global-services-desc  capitalize">
                This is the recommended path for companies that plan to
                implement a new application or process, or want to optimize a
                certain IT aspect.
              </p>
            </div>
            <div
              className={`col-md-4 ${
                isMobileView ? "col-12 mt-2" : ""
              } global-services-box-bg`}
            >
              <div className="text-light title roboto">
                Temporary HR Solutions
              </div>
              <p className="text-light global-services-desc  capitalize">
                This is the recommended path for organizations that need
                additional human resources for a limited period without hiring
                additional employees.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Approach;
