import React, { useState, useEffect } from "react";

import people from "../../../../src/images/people.svg";
import azure from "../../../../src/images/azure-aws.svg";

const Features = ({ features }) => {
  const [isMobileView, setIsMobileView] = useState(false);

  // Function to check if the viewport width is less than a certain value
  const checkMobileView = () => {
    setIsMobileView(window.innerWidth < 768); // Change 768 as per your breakpoint
  };

  // Add event listener on component mount
  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  return (
    <div className="serviceHotspot position-relative" id="overview">
      <div className="global-margin">
        <div className="row margin-top-botton">
          <div
            className={`col-md-6 ${isMobileView ? "col-12 text-center" : ""}`}
          >
           
            <div className="global-services-title roboto">
            Seamless DevOps, from Concept to Completion
            </div>
            <p className="global-services-desc ">
              Opsios DevOps Services and Solutions encompass DevOps
              Implementation Services, CI/CD Services, and Release and
              Configuration Management. They specialize Infrastructure as Code
              (laC) Services, Containerization, and DevSecOps, ensuring seamless
              integration and robust security in software development processes-
            </p>
            <img className={` ${isMobileView ? "w-100" : ""}`} src={people} alt="people"/>
            <p className="global-services-desc ">
              Opsios DevOps Services and Solutions encompass DevOps
              Implementation Services, CI/CD Services, and Release and
              Configuration Management. They specialize Infrastructure as Code
              (laC) Services, Containerization, and DevSecOps, ensuring seamless
              integration and robust security in software development processes-
            </p>
            <p className="global-services-desc ">
              Opsios DevOps Services and Solutions encompass DevOps
              Implementation Services, CI/CD Services, and Release and
              Configuration Management. They specialize Infrastructure as Code
              (laC) Services, Containerization, and DevSecOps, ensuring seamless
              integration and robust security in software development processes-
            </p>
          </div>
          <div
            className={`col-md-6 ${isMobileView ? "col-12 text-center" : ""}`}
          >
            <div className="global-services-title roboto">
            Seamless DevOps, from Concept to Completion
            </div>
            <p className="global-services-desc">
              Opsios DevOps Services and Solutions encompass DevOps
              Implementation Services, CI/CD Services, and Release and
              Configuration Management. They specialize Infrastructure as Code
              (laC) Services, Containerization, and DevSecOps, ensuring seamless
              integration and robust security in software development processes-
            </p>
            <p className="global-services-desc">
              Opsios DevOps Services and Solutions encompass DevOps
              Implementation Services, CI/CD Services, and Release and
              Configuration Management. They specialize Infrastructure as Code
              (laC) Services, Containerization, and DevSecOps, ensuring seamless
              integration and robust security in software development processes-
            </p>
            <img className={` ${isMobileView ? "w-100" : ""}`} src={azure} alt="azure"/>
            <p className="global-services-desc">
              Opsios DevOps Services and Solutions encompass DevOps
              Implementation Services, CI/CD Services, and Release and
              Configuration Management. They specialize Infrastructure as Code
              (laC) Services, Containerization, and DevSecOps, ensuring seamless
              integration and robust security in software development processes-
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
