import React, { useState, useEffect } from "react";

const Concept = ({ concept }) => {
  const [isMobileView, setIsMobileView] = useState(false);

  // Function to check if the viewport width is less than a certain value
  const checkMobileView = () => {
    setIsMobileView(window.innerWidth < 768); // Change 768 as per your breakpoint
  };

  // Add event listener on component mount
  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  return (
    <div className="serviceHotspot position-relative" id="overview">
      <div className="global-margin">
        <div className="row margin-top-botton">
          <div
            className={`col-md-6 ${isMobileView ? "col-12 text-center" : ""}`}
          >
            <div className="global-services-title roboto">{concept.title}</div>
            <p className="global-services-desc ">{concept.description}</p>
            <p className="global-services-desc ">{concept.description}</p>
            <p className="global-services-desc ">{concept.description}</p>
          </div>
          <div
            className={`col-md-6 ${isMobileView ? "col-12 text-center" : ""}`}
          >
            <div className="global-services-title roboto">{concept.title}</div>
            <p className="global-services-desc ">{concept.description}</p>
            <p className="global-services-desc ">{concept.description}</p>
            <p className="global-services-desc ">{concept.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Concept;
